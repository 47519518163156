<template>
  <div
    :id="`chapter-${currentChapter}`"
    class="view view-chapter view-scrollable"
  >
    <div
      class="
        cursor-default
        section-header
        absolute
        chapter-header
        layout-row layout-align-end-center
        section-nav
      "
      :data-chapter="currentChapter"
      ref="header"
      @mouseover="expandPartMenuHover(true)"
      @mouseleave="expandPartMenuHover(false)"
      @click="expandPartMenuToggle()"
    >
      <div class="chapter-icon">
        <img :src="chapterInfo.main" alt />
      </div>
      <div class="chapter-title h3">{{ chapterInfo.title }}</div>
      <div class="chapter-roman trajan">{{ chapterInfo.roman }}</div>
    </div>
    <div
      class="
        section-navigation
        chapter-navigation
        layout-align-center-center layout-column
        section-nav
        fixed
      "
      ref="nav"
      :style="{ right: scrollBarWidth }"
      :class="{ expanded: headerHover }"
    >
      <router-link
        v-for="part in chapterParts"
        :key="part.id"
        :to="{
          name: 'view-chapter-section',
          params: { slide: chapterInfo.slug, section: part.id },
        }"
        class="btn btn-square btn-square-small btn-section-nav"
        :title="`Part ${part.id}: ${part.title}`"
        @mouseover.native="hoveredPart = part.id"
        @mouseleave.native="hoveredPart = null"
        :class="{ hovered: part.id == hoveredPart }"
      >
        <span class="nav-part-no">{{ part.id }}</span>
        <span class="nav-part-title h4">{{ part.title }}</span>
      </router-link>
    </div>
    <div class="section-next chapter-next section-nav absolute" ref="next">
      <router-link
        :to="{
          name: 'view-chapter-section',
          params: { slide: chapterInfo.slug, section: nextPart.id },
        }"
        class="btn btn-next bottom-right"
        v-if="nextPart"
      >
        <div class="btn-next-text">
          <span class="trajan">Next</span>
          <span class="h4 part-title">{{ nextPart.title }}</span>
        </div>
        <div class="btn-next-icon btn-square">
          <i class="ai-right"></i>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'view-chapter-slide', params: { slide: nextChapter } }"
        class="btn btn-primary btn-primary-small"
        v-else-if="nextChapter"
      >
        <span>Next Chapter</span>
        <i class="ai-right"></i>
      </router-link>
      <router-link
        :to="{ name: 'view-enquire' }"
        class="btn btn-primary btn-primary-small"
        v-else-if="lastChapter"
      >
        <span>Enquire Now</span>
        <i class="ai-right"></i>
      </router-link>
    </div>
    <component
      v-if="viewLoaded"
      :is="componentInstance"
      :data="data"
      :leavingView="leavingView"
      :currentChapter="currentChapter"
      :part="partInfo"
      :show="showChild"
    ></component>
    <app-footer></app-footer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  storeDataLoading,
  beforeLeaveRoute,
  loadImages,
  zoomFunctions,
} from "@/mixins/core.js";

import Footer from "@/components/layout/Footer.vue";

export default {
  name: "ViewChapter",
  metaInfo() {
    return {
      title: `Chapter ${this.currentChapter}.${this.currentSection} - ${this.partInfo.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.data ? this.data.meta.description : "",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${this.$urls.buildUrl}` + window.location.pathname,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `Chapter ${this.currentChapter}.${this.currentSection} - ${this.partInfo.title}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.data ? this.data.meta.description : "",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${this.$urls.img}/opengraph/${this.currentChapter}-${this.currentSection}.jpg`,
        },
      ],
    };
  },
  components: {
    "app-footer": Footer,
  },
  mixins: [storeDataLoading, loadImages, beforeLeaveRoute, zoomFunctions],
  data() {
    return {
      tempTimelineButtonFlag: false,
      currentSection: Number(this.$route.params.section),
      currentSlide: this.$route.params.slide,
      data: null,
      viewLeaveDuration: 1100,
      viewEnterDelay: 200,
      loaderOpenDelay: 1000,
      leavingView: false,
      navTimeline: null,
      headerHover: false,
      hoveredPart: null,
    };
  },
  computed: {
    ...mapState(["menuButton", "scrollBarWidth"]),
    ...mapGetters(["getChapterPartBasics"]),
    componentInstance() {
      const chapter = this.currentChapter;
      const section = this.currentSection;
      return () => import(`@/views/chapters/${chapter}/${section}`);
    },
    imagesToLoad() {
      return Object.keys(this.data.images).map((key) => this.data.images[key]);
    },
    allImagesToLoad() {
      //add timeline and box slider images
      const timelineImages = this.data.timelines
        ? this.data.timelines.items
            .filter((item) => item.thumb)
            .map((item) => item.thumb)
        : [];

      const sliderImages = this.data.sliders
        ? this.data.sliders.slides
            .filter((item) => item.thumb)
            .map((item) => item.thumb)
        : [];

      return this.imagesToLoad.concat(timelineImages, sliderImages);
    },
    currentChapter() {
      return Number(this.currentSlide.charAt(0));
    },
    storeKey() {
      return `CHAPTER-${this.currentChapter}-${this.currentSection}`;
    },
    chapterInfo() {
      return this.getChapterPartBasics[this.currentChapter - 1];
    },
    chapterParts() {
      return this.chapterInfo.parts;
    },
    partInfo() {
      return this.chapterParts[this.currentSection - 1];
    },
    prevPart() {
      return this.currentSection == 1
        ? false
        : this.chapterParts[this.currentSection - 2];
    },
    nextPart() {
      return this.currentSection == this.chapterParts.length
        ? false
        : this.chapterParts[this.currentSection];
    },
    nextChapter() {
      return this.currentChapter == this.getChapterPartBasics.length
        ? false
        : this.getChapterPartBasics[this.currentChapter].slug;
    },
    lastChapter() {
      return this.currentChapter == this.getChapterPartBasics.length;
    },
    blocks() {
      if (this.data.modals) {
        const blocks = this.data.modals.map((modal) => modal.blocks);
        return []
          .concat(...blocks)
          .filter(
            (block) => block.type == "postslider" && block.data.src == "blog"
          );
      }
      return false;
    },
    postsInModals() {
      if (this.data.posts) {
        return this.data.posts;
      }
      if (this.blocks) {
        //extract posts from modals blocks
        return this.blocks.flatMap((block) => block.data);
      }
      return false;
    },
  },
  created() {
    //check if valid chapter
    const validChapters = this.$store.state.data.OUTLINE.data.chapters.map(
      (slide) => slide.id
    );

    if (validChapters.includes(this.$route.params.slide)) {
      //check if valid part
      let chapterIndex = validChapters.indexOf(this.$route.params.slide);

      if (
        !this.$store.state.data.OUTLINE.data.chapters[chapterIndex].parts[
          this.$route.params.section - 1
        ]
      ) {
        return;
      }

      this.getPartData();
    } else {
      return;
    }
  },
  updated() {
    this.getPartData();
  },
  methods: {
    getPartData() {
      //load relevant data for this chapter
      this.loadPartData({
        url: `/chapters/${this.currentChapter}/${this.currentSection}.json`,
        type: this.storeKey,
      });
    },
    setComponent(section, part) {
      this.viewChapter = section;
      this.viewPart = part;
    },
    viewLeaveAnimations(to, from) {
      this.resetAndPreventZoom();
      this.leavingView = true;
    },
    expandPartMenuHover(val) {
      //return if not desktop
      if (device.type != "desktop") {
        return;
      }

      if (val) {
        this.headerHover = true;
      } else {
        const vm = this;
        setTimeout(function () {
          vm.headerHover = false;
        }, 700);
      }
    },
    expandPartMenuToggle() {
      if (device.type == "desktop") {
        return;
      }
      this.headerHover = !this.headerHover;

      clearTimeout(this.sideMenuTimer);

      if (this.headerHover) {
        this.sideMenuTimer = setTimeout(() => {
          this.headerHover = false;
        }, 3500);
      }
    },
    sideMenuTimeout() {
      const vm = this;
      setTimeout(function () {
        if (vm.headerHover) {
          vm.headerHover = false;
        }
      }, 4000);
    },
    createTimeline() {
      const { header, nav, next } = this.$refs;
      const tlNav = new TimelineMax({
        paused: true,
      });

      tlNav
        .set(nav.childNodes, {
          clearProps: "all",
        })
        .staggerTo(
          header.childNodes,
          0.3,
          {
            y: "+=60",
            autoAlpha: 1,
            ease: Power2.easeInOut,
          },
          -0.1,
          0
        )
        .staggerTo(
          nav.childNodes,
          0.3,
          {
            x: "-=40",
            autoAlpha: 1,
            ease: Power2.easeInOut,
          },
          0.1,
          0
        )
        .to(
          next.childNodes,
          0.3,
          {
            y: "-=60",
            autoAlpha: 1,
            ease: Power2.easeInOut,
          },
          0
        );

      this.navTimeline = tlNav;
    },
  },
  watch: {
    menuOpen(val) {
      if (val) {
        this.resetAndPreventZoom();
      } else {
        this.resetAndAllowZoom();
      }
    },
    modalOpen(val) {
      if (val) {
        this.resetAndPreventZoom();
      } else {
        this.resetAndAllowZoom();
      }
    },
    $route(to, from) {
      //modal
      if (to.path === from.path && to.has != from.hash) {
        return;
      }

      this.currentSection = to.params.section;
      this.currentSlide = to.params.slide;
    },
    viewDataLoaded(val) {
      if (val) {
        this.data = this.$store.state.data[this.storeKey].data;
        this.preloadImages(this.allImagesToLoad, this.initView);

        //get posts data if exist
        if (this.postsInModals) {
          this.postsInModals.forEach((post) => {
            this.getPostsData(post);
          });
        }
      }
    },
    viewReady(val) {
      if (val) {
        this.resetAndAllowZoom();
        this.leavingView = false;
        this.createTimeline();
        this.navTimeline.delay(0.3).play();

        //preload post images
        this.preloadPostImages();
      } else {
        this.hoveredPart = null;
        this.navTimeline.reverse().timeScale(2);
        this.navTimeline = null;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    //modal
    if (to.path === from.path && to.has != from.hash) {
      next();
    } else {
      //leave view gracefully
      this.leaveView(to, from, next);
    }
  },
};
</script>

<style scoped>
.view-part {
  padding: calc(4 * var(--button-rem)) 0 calc(3.75 * var(--button-rem));
}
.section-nav {
  z-index: 5;
  font-size: var(--button-rem);
}
.section-header {
  height: 3.75em;
  right: 3.75em;
  top: 0;
}
.section-header > * {
  transform: translate(0, -60px);
  opacity: 0;
}
.chapter-icon {
  width: 3.75em;
  object-fit: contain;
  height: 3.75em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chapter-title {
  font-size: 1.438em;
  max-width: 8.087em;
  padding-left: 0.435em;
  padding-top: 0.2em;
}
.section-header[data-chapter="2"] .chapter-title {
  max-width: 8.348em;
}
.section-header[data-chapter="3"] .chapter-title {
  max-width: 5.304em;
}
.section-header[data-chapter="4"] .chapter-title {
  max-width: 7.13em;
}
.chapter-icon img {
  height: 80%;
  width: 80%;
  display: flex;
}
/* eagle
.section-header[data-chapter="3"] .chapter-title {
  max-width: 122px;
}
.section-header[data-chapter="3"] .chapter-icon img {
	width: 90%;
	height: auto;
	margin-top: 1px;
}
*/
.section-header[data-chapter="2"] .chapter-icon img {
  width: 100%;
  height: auto;
  margin-top: 0;
  align-self: flex-end;
}
.section-header[data-chapter="3"] .chapter-icon img {
  width: auto;
  height: 89%;
  align-self: flex-end;
}
.section-header[data-chapter="4"] .chapter-icon {
  justify-content: flex-end;
}
.section-header[data-chapter="4"] .chapter-icon img {
  width: auto;
  height: 78%;
  margin-top: 1px;
}
.chapter-roman {
  background: var(--grey);
  color: white;
  font-size: 2.909em;
  padding-top: 0.1em;
  width: 1.289em;
  height: 1.289em;
  align-items: center;
  justify-content: center;
  display: flex;
  letter-spacing: -0.1em;
  text-indent: -0.172em;
}
.section-navigation {
  right: calc(2.5em - 40px);
  top: 3.75em;
}
.section-navigation > * {
  right: -40px;
  opacity: 0;
}
.btn-section-nav {
  position: relative;
  font-family: "din";
  font-weight: 200;
}
.nav-part-title {
  font-size: 0.89em;
  height: 100%;
  transform: translate(100%, 0);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  padding: 0 0.391em 0 0.733em;
  background: var(--grey);
  color: var(--grey2);
  white-space: nowrap;
  z-index: 1;
  left: 0;
  color: black;
  top: 0;
}
.nav-part-no {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: var(--grey);
  color: var(--grey2);
  z-index: 2;
}
.btn-section-nav.router-link-active .nav-part-no {
  background: var(--blue);
  color: white;
}
.btn-section-nav.router-link-active .nav-part-title {
  background: var(--blue);
  color: white;
}
.btn-section-nav.hovered .nav-part-title {
  transform: translate(-100%, 0);
}
.section-next {
  bottom: 0;
  right: 0;
  overflow: hidden;
  font-size: var(--button-rem);
}
.section-next.chapter-next {
  padding: 0;
}
.section-next > * {
  transform: translate(0, 60px);
  opacity: 0;
}
.btn-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 0.8em;
  padding-top: 0;
  font-size: 1em;
}
.btn-next .btn-next-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: black;
  padding: 0.063em 1em 0.5em;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5),
    0px 0px 7px rgba(255, 255, 255, 0.5), 0px 0px 6px rgb(255, 255, 255),
    0px 0px 3px rgba(255, 255, 255, 0.9);
}
.btn-next .trajan {
  font-size: 1.413em;
  letter-spacing: 0.07em;
}
.btn-next .h4 {
  margin-top: -0.195em;
  font-size: 1.28em;
}
.btn-next .btn-next-icon {
  background: var(--blue);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0.25em;
  opacity: 0.9;
}
.btn-next .btn-next-icon i {
  font-size: 2.2em;
  line-height: 1em;
}
.btn-next:hover .btn-next-text {
  padding-right: 0.75em;
}
.btn-next:hover .btn-next-text .trajan {
  margin-right: -0.188em;
}
.btn-next:hover .btn-next-icon {
  opacity: 0.9;
}
.chapter-next .btn-primary {
  margin: 0 0.3em 0.4em 0;
}
.chapter-next .btn-primary span {
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5),
    0px 0px 7px rgba(255, 255, 255, 0.5), 0px 0px 6px rgb(255, 255, 255),
    0px 0px 3px rgba(255, 255, 255, 0.9);
  padding-top: 0.2em;
}

.chapter-next .btn-primary i {
  padding-top: 0.05em;
}

.chapter-next .btn-primary::after {
  top: 0;
}

.section-navigation.expanded .nav-part-title {
  transform: translate(-100%, 0);
}
.section-navigation.expanded .btn-section-nav:nth-child(2) .nav-part-title {
  transition-delay: 0.2s;
}
.section-navigation.expanded .btn-section-nav:nth-child(3) .nav-part-title {
  transition-delay: 0.4s;
}
.section-navigation.expanded .btn-section-nav:nth-child(4) .nav-part-title {
  transition-delay: 0.6s;
}
.section-navigation.expanded .btn-section-nav:nth-child(5) .nav-part-title {
  transition-delay: 0.8s;
}
.section-navigation.expanded .btn-section-nav:nth-child(6) .nav-part-title {
  transition-delay: 1s;
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1440px) {
  .chapter-title {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 767px) {
  .chapter-title {
    display: none;
  }
}
</style>
