<template>
  <div
    id="footer"
    class="absolute footer flex layout-row layout-align-start-center"
  >
    <div class="footer-enquire flex-initial layout-row" ref="enquireButton">
      <router-link
        to="/enquire/"
        class="btn btn-blue btn-square btn-footer-enquire"
      >
        <div class="footer-enquire-icon">
          <i class="ai-mail"></i>
        </div>
        <div class="footer-enquire-text trajan layout-align-center-center flex">
          <span>Enquire Now</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  components: {},
  mixins: [],
  data() {
    return {
      timelineEnquireButton: null,
    };
  },
  computed: {
    ...mapState(["menuButton"]),
  },
  watch: {
    menuButton(val) {
      if (val) {
        let menuButtonShowDelay = this.$route.name === "view-home" ? 0 : 0.6;

        this.showMenuButton(menuButtonShowDelay);
      } else {
        this.hideMenuButton();
      }
    },
  },
  mounted() {
    this.createTimeline();
  },
  methods: {
    createTimeline() {
      //menu button timeline
      const { enquireButton } = this.$refs;
      const tlMenuButton = new TimelineMax({
        paused: true,
      });

      tlMenuButton.to(enquireButton, 0.3, {
        y: "0",
        autoAlpha: 1,
        ease: Power2.easeInOut,
      });

      this.timelineEnquireButton = tlMenuButton;
    },
    showMenuButton(menuButtonShowDelay) {
      this.timelineEnquireButton.delay(menuButtonShowDelay).restart(true);
    },
    hideMenuButton() {
      this.timelineEnquireButton.reverse().timeScale(2);
    },
  },
};
</script>

<style scoped>
.view-enquire #footer,
.view-chapter-slide #footer,
.view-home #footer {
  display: none !important;
}
#footer {
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 5px 5px 0 0;
  z-index: 3;
}

.btn.btn-square.btn-footer-enquire {
  width: auto;
  opacity: 0.9;
  min-width: 3.75em;
}
.footer-enquire {
  top: 0;
  right: 0;
  z-index: 4;
  transform: translate(0, 60px);
  opacity: 0;
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.3);
}
.footer-enquire-text {
  background: var(--blue);
  color: white;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  font-size: 1.12rem;
  letter-spacing: 0;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.footer-enquire-text,
.footer-enquire-icon i {
  transition: 0.25s ease-out;
}
.footer-enquire-icon i {
  padding-bottom: 0 !important;
}
.footer-enquire-icon {
  width: 3.75em;
}
.footer-enquire-text span {
  padding: 0.3em 1em 0 0;
}
.btn-footer-enquire:hover .footer-enquire-text {
  width: auto;
  max-width: 11em;
}
</style>
